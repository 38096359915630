import React from "react";
import { t } from "i18next";
import { PDFDownloadLink } from "@react-pdf/renderer";
import useTestJournal from "../partials/TestJournal";
import TestingJournal from "../partials/wizard/steps/TestingJournal";
import Nav from "../partials/nav";
import { useRememberMe } from "../rememberMe";
import "./TestJournalPage.css";

const TestJournalPage = () => {
  const { TestJournalContent } = useTestJournal();
  const { testingHistory } = useRememberMe();

  if (testingHistory.length === 0) {
    return (
      <>
        <Nav />
        <main className="test-journal-page">
          <div className="container">
            <h1>{t("test-journal-h1-no-tests")}</h1>
            <p>{t("test-journal-p-no-tests")}</p>
            <a href="/" className="button test-journal-button">
              {t("nav-report")}
            </a>
          </div>
        </main>
      </>
    );
  }
  return (
    <>
      <Nav />
      <main className="test-journal-page">
        <div className="container">
          <h1>{t("test-journal-h1")}</h1>
          <p>{t("test-journal-p")}</p>
          <PDFDownloadLink
            className="button test-journal-button"
            fileName="test-results.pdf"
            document={<TestJournalContent />}
          >
            {t("download-journal")}
          </PDFDownloadLink>
          <br />
          <a href="/" className="button test-journal-button">
              {t("report-another")}
            </a>
          <br />
          <br />
          <TestingJournal testingHistory={testingHistory} />
        </div>
      </main>
    </>
  );
};

export default TestJournalPage;
