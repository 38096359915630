import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";

import shieldSend from "../../../images/shield-send.svg";
import { updateResults } from "../../../api/client";
import NormalizedPhoneInput from "../../NormalizedPhoneInput";
import { ValidationBanner, ERROR_USER_INPUT } from "./validationBanner";
import { t } from "i18next";
import { useRememberMe } from "../../../rememberMe";
import { useStoreContext, Steps } from "../../../Store";
import DatePicker from "../../DatePicker";
import Tooltip from "../../Tooltip";
import testKits from "../../testKits";

export const Step05 = () => {
  const {
    sex,
    setSex,
    race,
    setRace,
    ethnicity,
    setEthnicity,
    contactPhoneNumber,
    setContactPhoneNumber,
  } = useRememberMe();
  const { tests, testType, diagnosticType, setActiveStep, resultKey } = useStoreContext();
  const [symptomatic, setSymptomatic] = useState(null);
  const [readableRace, setReadableRace] = useState([]);
  const [readableEthnicity, setReadableEthnicity] = useState(null);
  const [textTestReminder, setTextTestReminder] = useState(null);
  const [isContactPhoneNumberValid, setIsContactPhoneNumberValid] =
    useState(true);

  const [optionalDetails, setOptionalDetails] = useState({
    symptomDate: "",
    sex: "",
    race: [],
    ethnicity: "",
    reminderMobileNumber: "",
  });

  //symptomdate related state
  const [symptomDate, setSymptomDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const [validationErrorCode, setValidationErrorCode] = useState(undefined);
  const [button, setButton] = useState("ready");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      setButton("loading");
      return;
    }
    if (symptomatic !== "Yes" || (symptomatic === "Yes" && symptomDate)) {
      setButton("ready");
      setValidationErrorCode(undefined);
    } else {
      setButton("disabled");
    }
  }, [symptomatic, symptomDate, loading]);

  useEffect(() => {
    if (symptomatic !== "Yes") {
      setShowDatePicker(false);
    } else {
      setShowDatePicker(true);
    }
  }, [symptomatic]);

  const handleSubmit = async () => {
    // Make sure our phone contact number is valid
    if (textTestReminder && !isContactPhoneNumberValid) {
      return;
    }

    let newOptionalDetails = {
      ...optionalDetails,
      submissionStatus: "optional_1",
      symptomatic: symptomatic,
      sex: sex,
      race: race.length > 0 ? race : undefined,
      ethnicity: ethnicity ? [ethnicity] : undefined,
      reminderMobileNumber: textTestReminder
        ? contactPhoneNumber || undefined
        : undefined,
    };

    const readableRaceField =
      readableRace.length > 0 ? readableRace : undefined;
    const readableEthnicityField = readableEthnicity
      ? [readableEthnicity]
      : undefined;

    if (symptomDate) {
      newOptionalDetails.symptomOnsetDate = DateTime.fromJSDate(symptomDate)
        .startOf("day")
        .toISO();
    }

    if (symptomatic !== "Yes") {
      delete newOptionalDetails.symptomOnsetDate;
    }

    try {
      setLoading(true);
      setOptionalDetails(newOptionalDetails);
      await updateResults(newOptionalDetails, resultKey);
      setActiveStep(Steps.Step06);

      window.dataLayer.push({
        event: "interactionEvent",
        event_name: "optional_questions_submit",
        symptoms: newOptionalDetails.symptomatic ? "exists" : undefined,
        symptomDate: symptomDate ? "exists" : undefined,
        race:
          readableRaceField && readableRaceField.length > 0
            ? "exists"
            : undefined,
        sex: newOptionalDetails.sex ? "exists" : undefined,
        ethnicity: readableEthnicityField ? "exists" : undefined,
        reminderMobileNumber: textTestReminder ? "exists" : undefined,
      });
    } catch (error) {
      setValidationErrorCode(error.message);
      console.error(error);
      setLoading(false);
    }
  };

  const handleClear = () => {
    setSymptomatic(null);
    setSex(null);
    setRace([]);
    setReadableRace([]);
    setEthnicity(null);
    setReadableEthnicity(null);
    setTextTestReminder(null);
    setSymptomDate("");
  };

  const handleRaceChange = (event) => {
    const { value, checked } = event.target;
    const readableValue = event.target.getAttribute("data-race");

    if (!checked) {
      setRace(race.filter((r) => r !== value));
      setReadableRace((currentReadableRaces) =>
        currentReadableRaces.filter((r) => r !== readableValue)
      );
    } else {
      setRace([...race, value]);
      setReadableRace((currentReadableRaces) => [
        ...currentReadableRaces,
        readableValue,
      ]);
    }
  };

  const handleEthnicityChange = (event) => {
    setEthnicity(event.target.value);
    setReadableEthnicity(event.target.getAttribute("data-ethnicity"));
  };
  const handleEthnicityClick = (event) => {
    if (ethnicity === event.target.value) {
      setEthnicity(null);
    }
  };

  const handleTestTextReminderChange = (event) => {
    const { value } = event.target;
    setTextTestReminder(value === "Yes" ? true : false);
  };
  const handleContactPhoneNumber = (newPhone) => {
    setContactPhoneNumber(newPhone);
  };
  const validateContactPhoneNumber = () => {
    if (!/^\d{10}$/.test(contactPhoneNumber)) {
      setIsContactPhoneNumberValid(false);
    } else {
      setIsContactPhoneNumberValid(true);
    }
  };

  const handleSymptomatic = (event) => {
    const { value } = event.target;
    setSymptomatic(value);

    if (value !== "Yes") {
      setSymptomDate("");
    }
  };

  const handleSymptomaticClick = (event) => {
    if (symptomatic === event.target.value) {
      setSymptomatic(null);
      setSymptomDate("");
    }
  };

  const handleSexChange = (event) => {
    setSex(event.target.value);
  };

  const handleSexClick = (event) => {
    if (sex === event.target.value) {
      setSex(null);
    }
  };

  const handleDate = (value) => {
    setSymptomDate(value);
  };

  const handleInputKeyDown = (event) => {
    if (["Up", "ArrowUp", "Left", "ArrowLeft"].includes(event.key)) {
      event.currentTarget.parentElement.previousSibling
        ?.querySelector("input")
        .focus();
    } else if (
      ["Down", "ArrowDown", "Right", "ArrowRight"].includes(event.key)
    ) {
      event.currentTarget.parentElement.nextSibling
        ?.querySelector("input")
        .focus();
    } else if (event.key === "Enter") {
      event.currentTarget.checked = !event.currentTarget.checked;
    }
  };
// Removing the timezone offset from the date string
  const firstAcceptableDate = new Date(`${testKits[testType].euaDate} Z-${new Date().getTimezoneOffset() / 60}:00`)
  firstAcceptableDate.setDate(firstAcceptableDate.getDate() - 10);

  const hasNegativeCovidResults = tests.some(
    ({ testResult, testTarget }) =>
      testTarget === "covid" && testResult === "Negative"
  );

  return (
    <section className="wizard-step-wrapper optionalDetails">
      <ValidationBanner errorCode={validationErrorCode}></ValidationBanner>
      <fieldset className="container">
        <header className="optional-header">
          <label className="big-label">{t("optional-qs")}</label>
        </header>

        <div className="optional-form">
          <fieldset className="form-group">
            <label className="label smaller-label" id="symptom-q">
              {t("symptom-q")}
            </label>
            <div
              className="radio-pills"
              role="radiogroup"
              aria-labelledby="symptom-q"
            >
              <div className="input-button" id="">
                <input
                  type="radio"
                  aria-keyshortcuts="Space"
                  id="noSymptoms"
                  name="symptoms"
                  value="No"
                  checked={symptomatic === "No"}
                  onChange={handleSymptomatic}
                  onClick={handleSymptomaticClick}
                />
                <label className="button tertiary" htmlFor="noSymptoms">
                  {t("no")}
                </label>
              </div>
              <div className="input-button" id="">
                <input
                  type="radio"
                  aria-keyshortcuts="Space"
                  id="yesSymptoms"
                  name="symptoms"
                  value="Yes"
                  checked={symptomatic === "Yes"}
                  onChange={handleSymptomatic}
                  onClick={handleSymptomaticClick}
                />
                <label className="button tertiary" htmlFor="yesSymptoms">
                  {t("yes")}
                </label>
              </div>
              <div className="input-button" id="">
                <input
                  type="radio"
                  aria-keyshortcuts="Space"
                  id="notsureSymptoms"
                  name="symptoms"
                  value="NotSure"
                  checked={symptomatic === "NotSure"}
                  onChange={handleSymptomatic}
                  onClick={handleSymptomaticClick}
                />
                <label className="button tertiary" htmlFor="notsureSymptoms">
                  {t("not-sure")}
                </label>
              </div>
            </div>
          </fieldset>
          {showDatePicker && (
            <DatePicker
              id={"symptom-datepicker"}
              label={t("symptom-date-q")}
              date={symptomDate ? new Date(symptomDate) : null}
              onChange={handleDate}
              minDate={firstAcceptableDate}
              maxDate={new Date()}
              required
            />
          )}
          <fieldset className="form-group sex">
            <label className="label smaller-label" id="sex-q">
              {t("sex-q")}
            </label>
            <div
              className="radio-pills"
              role="radiogroup"
              aria-labelledby="sex-q"
            >
              <div className="input-button">
                <input
                  type="radio"
                  aria-keyshortcuts="Space"
                  id="male"
                  name="sex"
                  value="M"
                  checked={sex === "M"}
                  onChange={handleSexChange}
                  onClick={handleSexClick}
                />
                <label className="button tertiary" htmlFor="male">
                  {t("male")}
                </label>
              </div>
              <div className="input-button">
                <input
                  type="radio"
                  aria-keyshortcuts="Space"
                  id="female"
                  name="sex"
                  value="F"
                  checked={sex === "F"}
                  onChange={handleSexChange}
                  onClick={handleSexClick}
                />
                <label className="button tertiary" htmlFor="female">
                  {t("female")}
                </label>
              </div>
              <div className="input-button">
                <input
                  type="radio"
                  aria-keyshortcuts="Space"
                  id="othersex"
                  name="sex"
                  value="O"
                  checked={sex === "O"}
                  onChange={handleSexChange}
                  onClick={handleSexClick}
                />
                <label className="button tertiary" htmlFor="othersex">
                  {t("other")}
                </label>
              </div>
            </div>
          </fieldset>

          <fieldset className="form-group race">
            <label className="label smaller-label" id="race-q">
              {t("race-q")}
              <em> {t("select-all")}</em>
            </label>
            <div
              className="radio-pills"
              role="listbox"
              aria-labelledby="race-q"
              aria-multiselectable="true"
            >
              <div
                className="input-button"
                role="option"
                aria-selected={race.includes("1002-5")}
                aria-labelledby="aioan-label"
              >
                <input
                  type="checkbox"
                  aria-labelledby="aioan-label"
                  id="aioan"
                  name="race"
                  data-race="American Indian or Alaska Native"
                  value="1002-5"
                  checked={race.includes("1002-5")}
                  onChange={handleRaceChange}
                  onKeyDown={handleInputKeyDown}
                />
                <label
                  className="button tertiary"
                  id="aioan-label"
                  htmlFor="aioan"
                >
                  {t("aioan")}
                </label>
              </div>
              <div
                className="input-button"
                role="option"
                aria-selected={race.includes("2028-9")}
                aria-labelledby="asian-label"
              >
                <input
                  type="checkbox"
                  aria-labelledby="asian-label"
                  id="asian"
                  name="race"
                  data-race="Asian"
                  value="2028-9"
                  checked={race.includes("2028-9")}
                  onChange={handleRaceChange}
                  onKeyDown={handleInputKeyDown}
                />
                <label
                  className="button tertiary"
                  id="asian-label"
                  htmlFor="asian"
                >
                  {t("asian")}
                </label>
              </div>
              <div
                className="input-button"
                role="option"
                aria-selected={race.includes("2054-5")}
                aria-labelledby="boaa-label"
              >
                <input
                  type="checkbox"
                  aria-labelledby="boaa-label"
                  id="boaa"
                  name="race"
                  data-race="Black or African American"
                  value="2054-5"
                  checked={race.includes("2054-5")}
                  onChange={handleRaceChange}
                  onKeyDown={handleInputKeyDown}
                />
                <label
                  className="button tertiary"
                  id="boaa-label"
                  htmlFor="boaa"
                >
                  {t("boaa")}
                </label>
              </div>
              <div
                className="input-button"
                role="option"
                aria-selected={race.includes("2076-8")}
                aria-labelledby="nhoopi-label"
              >
                <input
                  type="checkbox"
                  aria-labelledby="nhoopi-label"
                  id="nhoopi"
                  name="race"
                  data-race="Native Hawaiian or other Pacific Islander"
                  value="2076-8"
                  checked={race.includes("2076-8")}
                  onChange={handleRaceChange}
                  onKeyDown={handleInputKeyDown}
                />
                <label
                  className="button tertiary"
                  id="nhoopi-label"
                  htmlFor="nhoopi"
                >
                  {t("nhoopi")}
                </label>
              </div>
              <div
                className="input-button"
                role="option"
                aria-selected={race.includes("2106-3")}
                aria-labelledby="white-label"
              >
                <input
                  type="checkbox"
                  aria-labelledby="white-label"
                  id="white"
                  name="race"
                  data-race="White"
                  value="2106-3"
                  checked={race.includes("2106-3")}
                  onChange={handleRaceChange}
                  onKeyDown={handleInputKeyDown}
                />
                <label
                  className="button tertiary"
                  id="white-label"
                  htmlFor="white"
                >
                  {t("white")}
                </label>
              </div>
              <div
                className="input-button"
                role="option"
                aria-selected={race.includes("2131-1")}
                aria-labelledby="otherrace-label"
              >
                <input
                  type="checkbox"
                  aria-labelledby="otherrace-label"
                  id="otherrace"
                  name="race"
                  data-race="Other"
                  value="2131-1"
                  checked={race.includes("2131-1")}
                  onChange={handleRaceChange}
                  onKeyDown={handleInputKeyDown}
                />
                <label
                  className="button tertiary"
                  id="otherrace-label"
                  htmlFor="otherrace"
                >
                  {t("other")}
                </label>
              </div>
            </div>
          </fieldset>

          <fieldset className="form-group ethnicity">
            <label className="label smaller-label" id="ethnicity-q">
              {t("ethnicity-q")}
            </label>
            <div
              className="radio-pills"
              role="radiogroup"
              aria-labelledby="ethnicity-q"
            >
              <div className="input-button">
                <input
                  type="radio"
                  aria-keyshortcuts="Space"
                  id="hispanicOrLatino"
                  name="ethnicity"
                  data-ethnicity="Hispanic or Latino"
                  value="2135-2"
                  checked={ethnicity === "2135-2"}
                  onChange={handleEthnicityChange}
                  onClick={handleEthnicityClick}
                />
                <label className="button tertiary" htmlFor="hispanicOrLatino">
                  {t("hol")}
                </label>
              </div>
              <div className="input-button">
                <input
                  type="radio"
                  aria-keyshortcuts="Space"
                  id="notHispanicOrLatino"
                  name="ethnicity"
                  data-ethnicity="Not Hispanic or Latino"
                  value="2186-5"
                  checked={ethnicity === "2186-5"}
                  onChange={handleEthnicityChange}
                  onClick={handleEthnicityClick}
                />
                <label
                  className="button tertiary"
                  htmlFor="notHispanicOrLatino"
                >
                  {t("not-hol")}
                </label>
              </div>
            </div>
          </fieldset>
          {hasNegativeCovidResults && diagnosticType === "antigen" ? (
            <fieldset className="form-group reminder">
              <span>
                <label
                  className="label smaller-label"
                  id="text-test-reminder-q"
                >
                  {t("text-test-reminder-q")}
                </label>
                <Tooltip
                  id="text-test-reminder-tooltip"
                  text={t("text-test-reminder-tooltip")}
                />
              </span>
              <div
                className="radio-pills"
                role="radiogroup"
                aria-labelledby="text-test-reminder-q"
                aria-describedby="text-test-reminder-tooltip"
              >
                <div className="input-button">
                  <input
                    type="radio"
                    aria-keyshortcuts="Space"
                    id="textTestReminder"
                    name="textTestReminder"
                    value="Yes"
                    checked={textTestReminder === true}
                    onChange={handleTestTextReminderChange}
                  />
                  <label className="button tertiary" htmlFor="textTestReminder">
                    {t("yes")}
                  </label>
                </div>
                <div className="input-button">
                  <input
                    type="radio"
                    aria-keyshortcuts="Space"
                    id="textTestReminderNo"
                    name="textTestReminderNo"
                    value="No"
                    checked={textTestReminder === false}
                    onChange={handleTestTextReminderChange}
                  />
                  <label
                    className="button tertiary"
                    htmlFor="textTestReminderNo"
                  >
                    {t("no")}
                  </label>
                </div>
              </div>
              <div
                className="learn-more"
                dangerouslySetInnerHTML={{
                  __html: t("text-test-reminder-learn-more"),
                }}
              />
            </fieldset>
          ) : null}

          {textTestReminder ? (
            <fieldset
              className={`form-group required phone ${
                isContactPhoneNumberValid ? "" : "error"
              }`}
            >
              <label className="label smaller-label" id="phone-q">
                {t("phone-q")}
              </label>
              <NormalizedPhoneInput
                value={contactPhoneNumber}
                onChange={handleContactPhoneNumber}
                onBlur={validateContactPhoneNumber}
                inputProps={{
                  id: "phone",
                  name: "phone",
                  placeholder: t("phone-placeholder"),
                  "aria-labelledby": "phone-q",
                  "aria-describedby": "reminder-disclaimer",
                  "aria-required": "true",
                }}
              />
              <p className="reminder-disclaimer" id="reminder-disclaimer">
                {t("reminder-disclaimer")}
              </p>
            </fieldset>
          ) : null}
          {button === "disabled" && (
            <button
              className="button disabled"
              onClick={() => setValidationErrorCode(ERROR_USER_INPUT)}
              type="button"
              disabled
            >
              {t("input-err")}
            </button>
          )}
          {button === "ready" && (
            <button className="button" onClick={handleSubmit} type="button">
              {t("submit")} <img src={shieldSend} alt="" />
            </button>
          )}
          {button === "loading" && (
            <button className="button loading" type="button" disabled>
              {t("wait")} <i className="fa fa-spinner fa-spin"></i>
            </button>
          )}
          <button className="link-button" onClick={handleClear} type="button">
            {t("clear")}
          </button>
        </div>
      </fieldset>
    </section>
  );
};
