import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const CovidTestResult = ({ date, testResult }) => {
  return (
    <li key={date} className="test-history covid-test">
      <div className="tests">
        <div className="test">
          <div className="target">Covid:</div>
          <div className={`result ${testResult.toLowerCase()}`}>
            {testResult}
          </div>
        </div>
      </div>
      <div className="date">{new Date(date).toLocaleDateString()}</div>
    </li>
  );
};

CovidTestResult.propTypes = {
  date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  testResult: PropTypes.string,
};

const MultiplexTestResult = ({ date, tests }) => {
  const { t } = useTranslation();
  const getFormattedTestNames = (testTarget) => {
    if (testTarget === "fluA") {
      return `${t("flu")} A`;
    } else if (testTarget === "fluB") {
      return `${t("flu")} B`;
    }
    return t("covid");
  };

  return (
    <li key={date} className="test-history multiplex-test">
      <div className="tests">
        {tests.map(({ testTarget, testResult }) => (
          <div className="test" key={testTarget}>
            <div className="target">{getFormattedTestNames(testTarget)}:</div>
            <div className={`result ${testResult.toLowerCase()}`}>
              {t(testResult.toLowerCase())}
            </div>
          </div>
        ))}
      </div>
      <div className="date">{new Date(date).toLocaleDateString()}</div>
    </li>
  );
};
MultiplexTestResult.propTypes = {
  date: PropTypes.string,
  tests: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
};

const TestingJournal = ({ testingHistory }) => {
  const { t } = useTranslation();

  if (!testingHistory.length) {
    return null;
  }

  return (
    <div className="testing-journal">
      <ol>
        {testingHistory.map(({ date, testResult, tests }, index) =>
          testResult ? (
            <CovidTestResult
              date={date}
              testResult={testResult}
              key={`${date}_${index}`}
            />
          ) : (
            <MultiplexTestResult
              date={date}
              tests={tests}
              key={`${date}_${index}`}
            />
          )
        )}
      </ol>
    </div>
  );
};

TestingJournal.propTypes = {
  testingHistory: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

export default TestingJournal;
