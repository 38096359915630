import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { useThemeContext } from "../theme";
import whitelogo from "../images/mmtc-logo-white.svg";
import shield from "../images/shield-lock.svg";
import LanguageSwitcher from "./language-switcher";

export default function Attention() {
  const theme = useThemeContext();
  const { t, i18n } = useTranslation();
  const { resolvedLanguage } = i18n;
  const learnMoreURL = `https://learn.makemytestcount.org${
    resolvedLanguage === "es" ? "/es-ES" : ""
  }/frequently-asked-questions/1#learn-mmtc-faq-page-privacy`;
  return (
    <div className="attention-banner highlighted" role="note">
      <div className="container">
        <img src={shield} alt="Secure" />
        <span className="message">
          <a
            href="https://www.cisa.gov/topics/cyber-threats-and-advisories/federal-information-security-modernization-act"
            target="_blank"
            rel="noreferrer"
          >
          {t("attention-banner-text")}
          </a>
          <span> | </span>
          <a
            href={learnMoreURL}
            target="_blank"
            rel="noreferrer"
          >
           {t("learn-more")}
          </a>
        </span>
      </div>
      {theme.isEmbedded ? (
        <div className="make-my-test-count-banner">
          <a
            href="https://makemytestcount.com/"
            className="tooltip"
            aria-label="Site powered by Make My Test Count"
            target="_blank"
            rel="noreferrer"
          >
            <span className="powered-by">
              <span>Powered by:</span>
              <img
                className="logo"
                src={whitelogo}
                alt="Make My Test Count logo"
              />
            </span>
            <span className="tooltiptext">Embed this form on your site</span>
          </a>
          <LanguageSwitcher />
        </div>
      ) : null}
    </div>
  );
}
