import React, { forwardRef } from "react";
import PropTypes from "prop-types";

const propTypes = {
  text: PropTypes.string,
  name: PropTypes.string,
  alt: PropTypes.string,
  id: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(undefined),
  ]),
  value: PropTypes.string,
  onClick: PropTypes.func,
  checked: PropTypes.bool,
};

const defaultProps = {
  image: undefined,
};

const TestTypeButton = forwardRef(function TestTypeButton(
  { text, name, alt, id, image, onClick, value, checked },
  ref
) {
  const handleInputChange = (event) => {
    event.preventDefault();
    onClick(value);
  };

  // Automatically advance to the next button as if by normal radio button keyboard presses
  const handleKeyDown = (event) => {
    if (["Up", "ArrowUp", "Left", "ArrowLeft"].includes(event.key)) {
      event.currentTarget.parentElement.previousSibling
        ?.querySelector("button")
        .focus();
    } else if (
      ["Down", "ArrowDown", "Right", "ArrowRight"].includes(event.key)
    ) {
      event.currentTarget.parentElement.nextSibling
        ?.querySelector("button")
        .focus();
    }
  };

  return (
    <div className="test-type-button input-button">
      <button
        className="button"
        id={id}
        name={name}
        value={value}
        onClick={handleInputChange}
        onKeyDown={handleKeyDown}
        ref={ref}
        role="radio"
        type="button"
        aria-checked={checked}
        aria-keyshortcuts="ArrowLeft ArrowRight ArrowUp ArrowDown"
      >
        <span className="test-name">{text}</span>
        {image ? <img src={image} alt={alt} /> : null}
      </button>
    </div>
  );
});

TestTypeButton.propTypes = propTypes;
TestTypeButton.defaultProps = defaultProps;

export default TestTypeButton;
