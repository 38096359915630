import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import emailValidator from "email-validator";

import shieldSend from "../../../images/shield-send.svg";
import { updateResults } from "../../../api/client";
import { useRememberMe } from "../../../rememberMe";
import NormalizedPhoneInput from "../../NormalizedPhoneInput";
import states from "../states.json";
import { ValidationBanner, ERROR_USER_INPUT } from "./validationBanner";
import { Steps, useStoreContext } from "../../../Store";
import DatePicker from "../../DatePicker";

export const Step06 = () => {
  const { t } = useTranslation();
  const { setActiveStep, resultKey } = useStoreContext();
  const {
    contactFirstName,
    setContactFirstName,
    contactMiddleName,
    setContactMiddleName,
    contactLastName,
    setContactLastName,
    contactStreetAddress,
    setContactStreetAddress,
    contactCity,
    setContactCity,
    contactState,
    setContactState,
    contactEmail,
    setContactEmail,
    contactPhoneNumber,
    setContactPhoneNumber,
    contactDateOfBirth,
    setContactDateOfBirth,
    shouldShareInfo,
    setShouldShareInfo,
  } = useRememberMe();

  const [isContactEmailValid, setIsContactEmailValid] = useState(true);
  const [isContactPhoneNumberValid, setIsContactPhoneNumberValid] =
    useState(true);

  const [validationErrorCode, setValidationErrorCode] = useState(undefined);
  const [button, setButton] = useState("ready");
  const [loading, setLoading] = useState(false);

  const handleShareInfo = (event) => {
    setShouldShareInfo(true);
  };

  useEffect(() => {
    setButton("loading");
  }, [loading]);

  const handleYesOnKeyDown = (event) => {
    if (["Enter", "Space"].includes(event.key)) {
      setShouldShareInfo(true);
    }
  };

  const handleNoOnKeyDown = (event) => {
    if (["Enter", "Space"].includes(event.key)) {
      handleNextStep();
    }
  };

  const handleNextStep = () => {
    setShouldShareInfo(false);
    window.dataLayer.push({
      event: "interactionEvent",
      event_name: "form_complete",
    });
    setActiveStep(Steps.Final);
  };

  const handleFirstName = (event) => {
    setContactFirstName(event.target.value);
  };
  const handleMiddleName = (event) => {
    setContactMiddleName(event.target.value);
  };
  const handleLastName = (event) => {
    setContactLastName(event.target.value);
  };
  const handleStreetAddress = (event) => {
    setContactStreetAddress(event.target.value);
  };
  const handleCity = (event) => {
    setContactCity(event.target.value);
  };
  const handleState = (event) => {
    setContactState(event.target.value);
  };
  const handleContactEmail = (event) => {
    setContactEmail(event.target.value);
    validateAllFields();
  };
  const validateContactEmail = (showError = true) => {
    // Empty field is valid
    if (!contactEmail) {
      setIsContactEmailValid(true);
      return true;
    }
    const isValid = emailValidator.validate(contactEmail);
    // Dont show an error unless explicitly told to or if the field is becoming valid
    if (showError || (isValid && !isContactEmailValid)) {
      setIsContactEmailValid(isValid);
    }
    return isValid;
  };

  const handleContactPhoneNumber = (newPhone) => {
    setContactPhoneNumber(newPhone);
    validateAllFields();
  };
  const validateContactPhoneNumber = (showError = true) => {
    // Empty field is valid
    if (!contactPhoneNumber) {
      setIsContactPhoneNumberValid(true);
      return true;
    }
    const isValid = /^\d{10}$/.test(contactPhoneNumber);
    // Dont show an error unless explicitly told to or if the field is becoming valid
    if (showError || (isValid && !isContactPhoneNumberValid)) {
      setIsContactPhoneNumberValid(isValid);
    }
    return isValid;
  };

  const handleContactDateOfBirth = (value) => {
    setContactDateOfBirth(value.toISOString());
    validateAllFields();
  };

  const minDateOfBirth = new Date();
  minDateOfBirth.setFullYear(minDateOfBirth.getFullYear() - 110);
  const maxDateOfBirth = new Date();
  maxDateOfBirth.setFullYear(maxDateOfBirth.getFullYear() - 1);
  const validateAllFields = (showError = false) => {
    const isCurrentContactEmailValid = validateContactEmail(showError);
    const isCurrentContactPhoneNumberValid =
      validateContactPhoneNumber(showError);

    const isValid =
      isCurrentContactEmailValid && isCurrentContactPhoneNumberValid;

    if (!loading) {
      if (isValid) {
        setButton("ready");
      } else {
        setButton("disabled");
      }
    }

    return isValid;
  };

  const onChange = async (value) => {
    updateResults(value, resultKey);
  };

  const handleSubmit = async () => {
    if (!validateAllFields(true)) {
      return;
    }

    let contactInformation = {
      submissionStatus: "optional_2",
      contactFirstName,
      contactMiddleName,
      contactLastName,
      contactStreetAddress,
      contactCity,
      contactState,
      contactEmail,
      contactPhoneNumber,
      contactDateOfBirth: contactDateOfBirth ? contactDateOfBirth : undefined,
    };

    try {
      setLoading(true);
      await onChange(contactInformation);
      setActiveStep(Steps.Final);
      window.dataLayer.push({
        event: "interactionEvent",
        event_name: "optional_questions_submit",
      });
      window.dataLayer.push({
        event: "interactionEvent",
        event_name: "form_complete",
      });
    } catch (error) {
      setValidationErrorCode(error.message);
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    validateAllFields(false);
  });

  return (
    <section className="wizard-step-wrapper optionalDetails">
      <div className="container">
        <header className="optional-header">
          <label className="big-label">{t("share-contact-h")}</label>
        </header>
        <fieldset className="form-group reminder">
          <label className="label smaller-label" id="share-contact-q">
            {t("share-contact-q-1")}
            <div className="smaller-description">{t("share-contact-q-2")}</div>
          </label>
          <div
            className="radio-pills"
            role="radiogroup"
            aria-labelledby="share-contact-q"
          >
            <div className="input-button">
              <input
                type="radio"
                aria-keyshortcuts="Space Enter"
                id="shareInfo"
                name="shareInfo"
                value="Yes"
                checked={shouldShareInfo === true}
                onChange={handleShareInfo}
                onKeyDown={handleYesOnKeyDown}
              />
              <label className="button tertiary" htmlFor="shareInfo">
                {t("yes")}
              </label>
            </div>
            <div className="input-button">
              <input
                type="radio"
                aria-keyshortcuts="Space Enter"
                id="shareInfoNo"
                name="shareInfoNo"
                value="No"
                onChange={handleNextStep}
                onKeyDown={handleNoOnKeyDown}
              />
              <label className="button tertiary" htmlFor="shareInfoNo">
                {t("no")}
              </label>
            </div>
          </div>
        </fieldset>
        {shouldShareInfo ? (
          <>
            <ValidationBanner
              errorCode={validationErrorCode}
            ></ValidationBanner>
            <fieldset className="form-group row">
              <div className={`form-group first-name`}>
                <label className="label" htmlFor="firstName" id="first-name-q">
                  {t("first-name-q")}
                </label>
                <input
                  type="text"
                  value={contactFirstName}
                  id="contactFirstName"
                  autoComplete="given-name"
                  onChange={handleFirstName}
                  aria-labelledby="first-name-q"
                />
              </div>
              <div className="form-group middle-name">
                <label
                  className="label"
                  htmlFor="middleName"
                  id="middle-name-q"
                >
                  {t("middle-name-q")}
                </label>
                <input
                  type="text"
                  value={contactMiddleName}
                  id="contactMiddleName"
                  onChange={handleMiddleName}
                  aria-labelledby="middle-name-q"
                />
              </div>
              <div className={`form-group last-name`}>
                <label className="label" htmlFor="lastName" id="last-name-q">
                  {t("last-name-q")}
                </label>
                <input
                  type="text"
                  value={contactLastName}
                  autoComplete="family-name"
                  id="contactLastName"
                  onChange={handleLastName}
                  aria-labelledby="last-name-q"
                />
              </div>
            </fieldset>
            <fieldset className={`form-group`}>
              <label
                className="label"
                htmlFor="contactStreetAddress"
                id="street-address-q"
              >
                {t("street-address-q")}
              </label>
              <input
                type="text"
                value={contactStreetAddress}
                autoComplete="street-address"
                id="contactStreetAddress"
                onChange={handleStreetAddress}
                aria-labelledby="street-address-q"
              />
            </fieldset>
            <fieldset className="form-group row">
              <div className={`form-group`}>
                <label className="label" htmlFor="contactCity" id="city-q">
                  {t("city-q")}
                </label>
                <input
                  type="text"
                  value={contactCity}
                  autoComplete="address-level2"
                  id="contactCity"
                  onChange={handleCity}
                  aria-labelledby="city-q"
                />
              </div>
              <div className={`form-group`}>
                <label className="label" htmlFor="contactState" id="state-q">
                  {t("state-q")}
                </label>
                <select
                  value={contactState}
                  autoComplete="address-level1"
                  id="contactState"
                  onChange={handleState}
                  aria-labelledby="state-q"
                >
                  <option>---</option>
                  {Object.keys(states).map((state) => (
                    <option key={state} value={state}>
                      {states[state].name}
                    </option>
                  ))}
                </select>
              </div>
            </fieldset>
            <fieldset
              className={`form-group ${isContactEmailValid ? "" : "error"}`}
            >
              <label className="label" htmlFor="contactEmail" id="email-q">
                {t("email-q")}
              </label>
              <input
                type="email"
                value={contactEmail}
                autoComplete="email"
                id="contactEmail"
                onChange={handleContactEmail}
                onBlur={validateAllFields}
                aria-labelledby="email-q"
                aria-invalid={isContactEmailValid ? "false" : "true"}
              />
            </fieldset>
            <fieldset
              className={`form-group ${
                isContactPhoneNumberValid ? "" : "error"
              }`}
            >
              <label className="label" htmlFor="phone" id="phone-number-q">
                {t("phone-number-q")}
              </label>
              <NormalizedPhoneInput
                value={contactPhoneNumber}
                onChange={handleContactPhoneNumber}
                onBlur={validateAllFields}
                inputProps={{
                  id: "phone",
                  name: "phone",
                  placeholder: t("phone-placeholder"),
                  "aria-labelledby": "phone-number-q",
                  "aria-required": "true",
                  "aria-invalid": isContactPhoneNumberValid ? "false" : "true",
                }}
              />
            </fieldset>
            <DatePicker
              id={"contactDateOfBirth"}
              label={t("date-of-birth-q")}
              date={contactDateOfBirth ? new Date(contactDateOfBirth) : null}
              onChange={handleContactDateOfBirth}
              minDate={minDateOfBirth}
              maxDate={maxDateOfBirth}
              dayPickerProps={{
                defaultMonth: new Date(1990, 0),
                placeholder: new Date(1990, 0),
              }}
              required
            />
            {button === "disabled" && (
              <button
                className="button disabled"
                onClick={() => setValidationErrorCode(ERROR_USER_INPUT)}
                type="button"
                disabled
              >
                {t("input-err")}
              </button>
            )}
            {button === "ready" && (
              <button className="button" onClick={handleSubmit} type="button">
                {t("submit")} <img src={shieldSend} alt="" />
              </button>
            )}
            {button === "loading" && (
              <button className="button loading" type="button" disabled>
                {t("wait")} <i className="fa fa-spinner fa-spin"></i>
              </button>
            )}
          </>
        ) : null}
      </div>
    </section>
  );
};
