import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { capitalCase } from "change-case";

import { submitResults } from "../../../api/client";

import { ValidationBanner, ERROR_USER_INPUT } from "./validationBanner";
import shieldSend from "../../../images/shield-send.svg";
import testKits from "../../testKits";
import { useThemeContext } from "../../../theme";
import { useStoreContext, Steps } from "../../../Store";
import { useRememberMe } from "../../../rememberMe";
import DatePicker from "../../DatePicker";
import Tooltip from "../../Tooltip";

const experimentCookieRegex = /_vis_opt_exp_(?<expid>\d+)_combi=(?<varid>\d+)/;

export const Step04 = () => {
  const { i18n } = useTranslation();
  const { tests, testType, setActiveStep, setResultKey } = useStoreContext();
  const { themeTestTypes = [] } = useThemeContext();
  const {
    contactCity,
    setContactCity,
    contactState,
    setContactState,
    testingHistory,
    setTestingHistory,
    age,
    setAge,
    zip,
    setZip,
    shouldRememberMe,
    setShouldRememberMe,
  } = useRememberMe();

  const [requiredDetails, setRequiredDetails] = useState({
    testDate: "",
    age: "",
    zip: "",
  });

  //Date related state
  const [testDate, setTestDate] = useState(null);
  const [testDateValid, setTestDateValid] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  //age related state
  const [ageValid, setAgeValid] = useState(age ? true : false);
  const [ageHasBeenSet, setAgeHasBeenSet] = useState(age ? true : false);

  //zip related state
  const [zipValid, setZipValid] = useState(zip ? true : false);
  const [zipHasBeenSet, setZipHasBeenSet] = useState(zip ? true : false);

  const [validationErrorCode, setValidationErrorCode] = useState(undefined);
  const [button, setButton] = useState("disabled");

  useEffect(() => {
    if (ageValid && testDateValid && zipValid) {
      setButton("ready");
      setValidationErrorCode(undefined);
    } else {
      setButton("disabled");
    }
  }, [ageValid, zipValid, testDateValid]);

  const getExperimentCookies = (searchParameters) => {
    const gaExperiments = {};
    if (
      searchParameters.has("experiment_id") &&
      searchParameters.has("variant_id")
    ) {
      const GAExperimentId = searchParameters.get("experiment_id");
      const GAExperimentVariant = searchParameters.get("variant_id");
      gaExperiments[GAExperimentId] = GAExperimentVariant;
    } else {
      // Experiment cookie is in the format of _vis_opt_exp_19_combi=3;
      // where _vist_opt_exp_X is the experiment id and the cookie value is the variant
      const experimentsCookies = document.cookie
        .split("; ")
        .filter((row) => row.startsWith("_vis_opt_exp"));
      experimentsCookies?.forEach((experiment) => {
        if (!experimentCookieRegex.test(experiment)) {
          return;
        }
        const { expid, varid } = experimentCookieRegex.exec(experiment).groups;
        gaExperiments[expid] = varid;
      });
    }

    return gaExperiments;
  };

  async function onChange(value) {
    if (!tests.length) {
      console.error("Test result not selected");
    }
    const currentSearchParams = new URLSearchParams(window.location.search);

    setRequiredDetails(value);
    let result = {
      ...requiredDetails,
      testTakenDate: value.testDate,
      age: value.age,
      zipcode: value.zip,
      testType,
      testResults: tests,
      origin,
      languageCode: i18n.resolvedLanguage,
      utmParameters: {
        utm_source: currentSearchParams.get("utm_source"),
        utm_medium: currentSearchParams.get("utm_medium"),
        utm_campaign: currentSearchParams.get("utm_campaign"),
        utm_term: currentSearchParams.get("utm_term"),
        utm_content: currentSearchParams.get("utm_content"),
      },
      gaExperiments: getExperimentCookies(currentSearchParams),
    };

    let apiResult = await submitResults(result);
    setResultKey(apiResult.resultKey);

    // Set our found city and state from zip code
    const { city = "", state = "" } = apiResult;
    // If they have one saved, don't overwrite what they already saved
    if (!contactCity) {
      setContactCity(capitalCase(city));
    }
    if (!contactState) {
      setContactState(state);
    }

    // Add the submission to the testing history on device
    const newTestingHistory = testingHistory.concat([
      {
        tests,
        date: value.testDate,
      },
    ]);
    setTestingHistory(newTestingHistory);
  }

  const handleSubmit = async () => {
    let requiredDetails = {
      testDate: DateTime.fromISO(testDate).startOf("day").toISO(),
      age: parseInt(age),
      zip: zip,
    };

    try {
      setButton("loading");
      await onChange(requiredDetails);
      setActiveStep(Steps.Step05);
      window.dataLayer.push({
        event: "interactionEvent",
        event_name: "test_details_submit",
        test_date: testDate ? "exists" : undefined,
        age: requiredDetails.age ? "exists" : undefined,
        zip_code: requiredDetails.zip ? "exists" : undefined,
      });
    } catch (error) {
      console.error(error);
      setButton("ready");
      setValidationErrorCode(error.message);
    }
  };

  const selectRelativeDate = (event) => {
    const value = event.target.value;
    setShowDatePicker(false);
    handleDate(value, "button");
  };

  const openDatepicker = () => {
    setShowDatePicker(true);
    setTestDate(null);
    setTestDateValid(false);
  };

  const handleDate = (value) => {
    if (value === "today") {
      setTestDate(DateTime.now().toISODate());
      setTestDateValid(true);
    } else if (value === "yesterday") {
      setTestDate(DateTime.now().plus({ days: -1 }).toISODate());
      setTestDateValid(true);
    } else {
      setTestDate(DateTime.fromJSDate(value).toISODate());
      setTestDateValid(true);
    }
  };
  const handleAge = (event) => {
    const result = event.target.value.replace(/\D/g, "");
    setAge(result);
    setAgeHasBeenSet(true);
    const resultAge = parseInt(result);
    if (isNaN(resultAge)) {
      setAgeValid(false);
    }
    if (!Number.isInteger(result)) {
      setAgeValid(false);
    }
    if (result < 1 || result > 110 || result === "") {
      setAgeValid(false);
    } else {
      setAgeValid(true);
    }
  };

  const handleZip = (event) => {
    const result = event.target.value.replace(/\D/g, "");
    setZip(result);
    if (result.length === 5) {
      setZipHasBeenSet(true);
    }
    if (
      !/^[0-9]{5}$/.test(result) ||
      result === "00000" ||
      result === "11111" ||
      result === "99999"
    ) {
      setZipValid(false);
    } else {
      setZipValid(true);
      setZipHasBeenSet(true);
    }
  };

  const handleRememberMe = (event) => {
    const isChecked = event.target.checked;
    setShouldRememberMe(isChecked);
    // On clearing of the field, disable the button
    if (shouldRememberMe && !isChecked) {
      setButton("disabled");
      setZipHasBeenSet(false);
      setAgeHasBeenSet(false);
    }
  };

  const preventNonInteger = (event) => {
    var invalidChars = ["-", "+", "e", "."];
    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    } else if (["ArrowUp", "ArrowRight"].includes(event.key)) {
      event.preventDefault();
      let result = (parseInt(age) || 0) + 1;
      if (result > 110) {
        result = 110;
      }
      setAge(result);
    } else if (["ArrowDown", "ArrowLeft"].includes(event.key)) {
      event.preventDefault();
      let result = (parseInt(age) || 0) - 1;
      if (result < 0) {
        result = 0;
      }
      setAge(result);
    }
  };

  const goBack = () => {
    if (themeTestTypes.length) {
      setActiveStep(Steps.Step02);
    } else {
      setActiveStep(Steps.Step03);
    }
  };

  const goToTestSelection = () => {
    setActiveStep(Steps.Step03);
  };

  // Removing the timezone offset from the date string
  const firstAcceptableDate = new Date(`${testKits[testType].euaDate} Z-${new Date().getTimezoneOffset() / 60}:00`)

  let selectedTestBanner = null;
  if (themeTestTypes.length > 1) {
    selectedTestBanner = (
      <button
        className="selected-test-banner"
        onClick={goToTestSelection}
        type="button"
        aria-label="Back to Step 3"
      >
        <div>
          {t("test-type-banner-text")} {testKits[testType].name}.
        </div>
        <div className="link">{t("test-type-banner-action")}</div>
      </button>
    );
  } else if (themeTestTypes.length === 1) {
    selectedTestBanner = (
      <div className="selected-test-banner">
        <div>
          {t("test-type-banner-text")} {testKits[testType].name}.
        </div>
      </div>
    );
  }

  return (
    <>
      {selectedTestBanner}
      <section className="wizard-step-wrapper requiredDetails">
        <ValidationBanner errorCode={validationErrorCode}></ValidationBanner>
        <button
          className="back-button"
          onClick={goBack}
          type="button"
          title={`Back to Step ${themeTestTypes.length ? 1 : 2}`}
          aria-label={`Back to Step ${themeTestTypes.length ? 1 : 2}`}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <div className="container">
          <fieldset className="form-group test-date">
            <label className="big-label first-label" id="test-date-q-1">
              {t("test-date-q-1")}
            </label>
            <div
              className="radio-pills"
              role="radiogroup"
              aria-labelledby="test-date-q-1"
            >
              <div className="input-button" id="">
                <input
                  type="radio"
                  id="today"
                  name="test-date"
                  value="today"
                  onClick={selectRelativeDate}
                  aria-keyshortcuts="Space"
                />
                <label className="button" htmlFor="today">
                  {t("today")}
                </label>
              </div>
              <div className="input-button" id="">
                <input
                  type="radio"
                  id="yesterday"
                  name="test-date"
                  value="yesterday"
                  onClick={selectRelativeDate}
                  aria-keyshortcuts="Space"
                />
                <label className="button" htmlFor="yesterday">
                  {t("yesterday")}
                </label>
              </div>
              <div className="input-button" id="otherdayWrap">
                <input
                  type="radio"
                  id="otherday"
                  name="test-date"
                  value="otherday"
                  onClick={openDatepicker}
                  aria-keyshortcuts="Space"
                />
                <label className="button last-pill" htmlFor="otherday">
                  {t("other")}
                </label>
              </div>
            </div>
          </fieldset>
          {showDatePicker ? (
            <DatePicker
              id={"test-datepicker"}
              label={t("test-date-q-2")}
              date={testDate ? DateTime.fromISO(testDate).toJSDate() : null}
              onChange={handleDate}
              minDate={firstAcceptableDate}
              maxDate={new Date()}
              dialogPosition="top"
              required
            />
          ) : null}
          <fieldset className="form-group">
            <label className="big-label" htmlFor="age" id="age-q">
              {t("age-q")}
            </label>
            <input
              type="number"
              id="age"
              max="120"
              pattern="[0-9]*"
              inputMode="numeric"
              name="age"
              onKeyDown={preventNonInteger}
              onChange={handleAge}
              value={age ? Number(age).toString() : age}
              placeholder="e.g., 44"
              aria-labelledby="age-q"
              aria-describedby="age-val-err"
              aria-invalid={!ageHasBeenSet || ageValid ? "false" : "true"}
              aria-keyshortcuts="ArrowUp ArrowDown"
              aria-errormessage="age-val-err"
              aria-required="true"
            />
            {!ageHasBeenSet || ageValid ? null : (
              <div className="validation-error age-message" id="age-val-err">
                {t("age-val-err")}
              </div>
            )}
          </fieldset>
          <fieldset className="form-group">
            <label className="big-label" htmlFor="zip" id="home-zip-q">
              {t("home-zip-q")}
            </label>

            <input
              type="text"
              pattern="[0-9]{5}"
              inputMode="numeric"
              id="zip"
              maxLength="5"
              name="zip"
              value={zip}
              onChange={handleZip}
              placeholder="e.g., 11111"
              aria-labelledby="home-zip-q"
              aria-describedby="zip-val-err"
              aria-invalid={!zipHasBeenSet || zipValid ? "false" : "true"}
              aria-required="true"
              aria-errormessage="zip-val-err"
            />
            {!zipHasBeenSet || zipValid ? null : (
              <div className="validation-error zip-message" id="zip-val-err">
                {t("zip-val-err")}
              </div>
            )}
          </fieldset>

          <fieldset className="form-group remember-me">
            <input
              type="checkbox"
              checked={shouldRememberMe}
              onChange={handleRememberMe}
              id="remember-me"
              aria-labelledby="remember-me-label"
              aria-required="false"
              aria-describedby="remember-me-tooltip"
              tabIndex={0}
            />
            <label id="remember-me-label" htmlFor="remember-me">
              {t("remember-me-q")}
            </label>
            <Tooltip
              id="remember-me-tooltip"
              text={t("remember-me-tooltip")}
              position="right"
            />
          </fieldset>
          {button === "disabled" && (
            <button
              className="button disabled"
              onClick={() => setValidationErrorCode(ERROR_USER_INPUT)}
              disabled
            >
              {t("answer-all-btn")}
            </button>
          )}
          {button === "ready" && (
            <button className="button" onClick={handleSubmit}>
              {t("next")} <img src={shieldSend} alt="" />
            </button>
          )}
          {button === "loading" && (
            <button className="button loading" type="button" disabled>
              {t("wait")} <i className="fa fa-spinner fa-spin"></i>
            </button>
          )}
        </div>
      </section>
    </>
  );
};
